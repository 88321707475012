const baseUrl = (process.env.REACT_APP_BACKEND_BASE_URL ? process.env.REACT_APP_BACKEND_BASE_URL : '')


export const baseProteinsUrl = `${baseUrl}/api/proteins/`;
export const helloUrl = `${baseProteinsUrl}hello/`;
export const listUrl = `${baseProteinsUrl}list/`;

export const proteinKnownStructureUrl = (proteinName) => {
  return `${baseProteinsUrl}${proteinName}/known_structure/`
};
export const proteinPredictedStructureUrl = (proteinName) => {
  return `${baseProteinsUrl}${proteinName}/predicted_structure/`
};
export const proteinMutationMatrixUrl = (proteinName) => {
  return `${baseProteinsUrl}${proteinName}/mutation_matrix/`
};
export const proteinECPDFUrl = (proteinName) => {
  return `${baseProteinsUrl}${proteinName}/ec_pdf/`
};
export const proteinJobStatsSummaryUrl = (proteinName) => {
  return `${baseProteinsUrl}${proteinName}/job_stats_summary/`
};
