import React, { useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Document, Page, pdfjs } from 'react-pdf/dist/umd/entry.webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import {
  listUrl,
  proteinECPDFUrl,
  proteinMutationMatrixUrl,
} from '../../routes';
import JobStatsSummaryTable from '../../components/JobStatsSummaryTable';
import StructureViewer from '../../components/StructureViewer';
import MutationPanel from '../../components/results/Mutations';
import { makeDataFrame } from '../../utils/Helpers';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const useStyles = makeStyles({
  containerDefault: {
    textAlign: 'left',
    paddingTop: '40px',
    maxWidth: '90%',
  },
  rowECStats: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px',
  },
});

export default function SingleProteinPage() {
  const classes = useStyles();
  const { proteinName } = useParams();
  const [mutationMatrixData, setMutationMatrixData] = useState({});
  const [hasExperimentalData, setHasExperimentalData] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProteinDataStatus = async () => {
      setLoading(true);
      const proteinsWithData = await fetch(listUrl)
      .then((response) => {
        return response.json();
      });
      setHasExperimentalData(proteinsWithData.indexOf(proteinName.toLowerCase()) >= 0);
      setLoading(false);
    };
    fetchProteinDataStatus();
  }, [proteinName]);

  useEffect(() => {
    const fetchMutationMatrixData = async () => {
      const fetchedMutationMatrixData = await fetch(proteinMutationMatrixUrl(proteinName.toLowerCase()))
      .then((response) => {
        return response.text();
      }).then((mutationMatrixCSVStr) => {
        // Then parse it into a list of dictionaries, which makeDataFrame needs
        let mutationMatrixCSVArr = mutationMatrixCSVStr.trim().split('\n');
        let rowIndex = 0;
        const headers = mutationMatrixCSVArr[rowIndex].split(',');
        rowIndex++;

        var mutationMatrixArr = [];
        for (rowIndex; rowIndex < mutationMatrixCSVArr.length; rowIndex++) {
          let row = mutationMatrixCSVArr[rowIndex].split(',');
          var rowDict = {};
          for (let colIndex = 0; colIndex < headers.length; colIndex++) {
            rowDict[headers[colIndex]] = row[colIndex];
          }
          mutationMatrixArr.push(rowDict);
        }
        return mutationMatrixArr;
      });
      setMutationMatrixData(
        fetchedMutationMatrixData.length > 0
        ? { table: makeDataFrame(fetchedMutationMatrixData) }
        : {}
      );
    };
    if (hasExperimentalData) {
      fetchMutationMatrixData();
    }
  }, [hasExperimentalData, proteinName]);

  const onClick = () => {
    alert('You found me!!');
  }

  return (
    <>
      <Container className={classes.containerDefault}>
        <Row>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <h3>{proteinName}</h3>
          </Col>
          {
            // hasExperimentalData &&
            // <Col xs={{ span: 7, offset: 1 }} sm={{ span: 4, offset: 4 }} md={{ span: 3, offset: 5 }} lg={{ span: 3, offset: 5 }} xl={{ span: 3, offset: 5 }}>
            //   <Button variant='primary' onClick={ onClick } style={{ float: 'right' }}>
            //     Download Data
            //   </Button>
            // </Col>
          }
        </Row>
        <hr/>
        <Row>
          <Col xs={12} sm={4} md={4} lg={4} xl={4}>
            <StructureViewer proteinName={proteinName.toLowerCase()}/>
          </Col>
          {
            hasExperimentalData &&
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Paper variant='outlined' square style={{height: '100%'}}>
                <MutationPanel
                  mutationData={mutationMatrixData}
                />
              </Paper>
            </Col>
          }
        </Row>
        {
          hasExperimentalData
          ?
          <Row className={classes.rowECStats}>
            <Col xs={12} sm={6} md={6} lg={{ span: 4, offset: 1 }} xl={{ span: 4, offset: 1 }} style={{ paddingBottom: '40px' }}>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <Document
                    file={proteinECPDFUrl(proteinName.toLowerCase())}
                  >
                    <Page pageNumber={1} width={width < 400 ? width : 400}/>
                  </Document>
                )}
              </AutoSizer>
            </Col>
            <Col xs={12} sm={12} md={12} lg={{ span: 6 }} xl={{ span: 4 }}>
              <JobStatsSummaryTable proteinName={proteinName.toLowerCase()}/>
            </Col>
          </Row>
          :
          !loading &&
          <h3 style={{textAlign: 'center', paddingTop: '2em'}}>
            We don't have any experimental data for this protein yet—check back soon!
          </h3>
        }
      </Container>
    </>
  )
}
