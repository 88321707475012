import './App.css';

import Main from './components/Main';
import NavigationBar from './components/NavigationBar';

function App() {
  return (
    <div className='App'>
      <NavigationBar />
      <Main/>
    </div>
  );
}

export default App;
