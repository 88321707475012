import React, { useState, useMemo } from 'react';
import { Button, ButtonGroup, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { interpolateRdBu } from 'd3';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Heatmap from './../common/Heatmap';
import { createColorMapper } from './../../utils/Helpers';

const AA_LIST_PROPERTY = 'WFYPMILVAGCSTQNDEHRK';

export const reorderValues = (x, targetColumn, order, reverse) => {
  // const ordered = Array(x.length);
  // note this needs to be based on order rather than data, since self-mutant not in data table
  const ordered = Array(order.length).fill(0);
  x.forEach(r => {
    // note that position mapping is inefficient and should rather be done with a map
    // console.log(r.subs, order.indexOf(r.subs));
    const idx = reverse
      ? order.length - order.indexOf(r.subs) - 1
      : order.indexOf(r.subs);
    ordered[idx] = r[targetColumn];
  });

  return ordered;
};

const extractMutationData = (
  mutationTable,
  substitutionOrder,
  reverseOrder
) => {
  let segments = null;
  let positions = [];
  let wildtype = [];
  let epistaticMatrix = [];
  let independentMatrix = [];

  // set segments variable to list if segment is present, otherwise leave as null
  if (mutationTable.getColumnNames().includes('segment')) {
    segments = [];
  }

  // TODO: make sure order of groupby is guaranteed
  // TODO: remove head
  const dataByPos = mutationTable.groupBy(row =>
    row.segment ? row.segment + '__' + row.pos : row.pos
  );

  // go through sub-dataframe for each position
  dataByPos.forEach(x => {
    const xArr = x.toArray();

    // add position and segment (if present)
    if (xArr.segment) {
      segments.push(xArr[0].segment);
    }
    positions.push(xArr[0].pos);

    // store wildtype residue/base
    wildtype.push(xArr[0].wt);

    // store current data column in right order of entries
    // reverse order so rendered in same way as by pipeline
    epistaticMatrix.push(
      reorderValues(
        xArr,
        'prediction_epistatic',
        substitutionOrder,
        reverseOrder
      )
    );
    independentMatrix.push(
      reorderValues(
        xArr,
        'prediction_independent',
        substitutionOrder,
        reverseOrder
      )
    );
  });

  return {
    segments: segments,
    positions: positions,
    wildtype: wildtype,
    epistaticMatrix: epistaticMatrix,
    independentMatrix: independentMatrix
  };
};

// TODO: replace with global helper function
/*
export const createColorMapper = (data) => {
  const minVal = data.min();
  const maxVal = data.max();
  const range = Math.max(Math.abs(minVal), Math.abs(maxVal));

  return v => color(interpolateRdBu(1 - (v + range) / (2 * range))).hex();
};
*/

/*
  EVmutation results panel

  TODO:
  - allow to toggle panels
  - implement all settings (also in parent component)
  - allow to select which matrix to display (epistatic or independent)
*/
export const MutationPanel = ({
  mutationData,
}) => {
  const [showEpistaticModel, setShowEpistaticModel] = useState(true);

  const cellSize = 10;
  const substitutionOrder = AA_LIST_PROPERTY;
  const reverseSubstitutionOrder = true;

  let yLabels = Array.from(AA_LIST_PROPERTY);
  if (reverseSubstitutionOrder) {
    yLabels.reverse();
  }

  let extractedData;

  // prepare data for heatmap
  if (mutationData.table) {
    extractedData = extractMutationData(
      mutationData.table,
      substitutionOrder,
      reverseSubstitutionOrder
    );
  }

  // colormap for heatmap rendering;
  // memoize for structure colormap update below
  const curColorMapper = useMemo(() => {
    return mutationData.table
      ? createColorMapper(
          mutationData.table.getSeries(
            showEpistaticModel
              ? 'prediction_epistatic'
              : 'prediction_independent'
          ),
          interpolateRdBu,
          true,
          true
        )
      : null;
  }, [mutationData.table, showEpistaticModel]);

  /*
    Legend renderer shared between mutation heatmap and 3D viewer panels
  */
  const renderLegend = () => {
    let epistaticData;
    let independentData;

    if (mutationData && mutationData.table) {
      epistaticData = mutationData.table.getSeries('prediction_epistatic');
      independentData = mutationData.table.getSeries('prediction_independent');
    }

    const currentData = showEpistaticModel ? epistaticData : independentData;
    if (!currentData) {
      return null;
    }

    const colorSteps = 20;
    // assemble colors
    const stepSize = (currentData.max() - currentData.min()) / colorSteps;
    const colors = [];
    for (let i = 0; i <= colorSteps; i++) {
      colors.push(curColorMapper(currentData.min() + i * stepSize));
    }

    const backgroundStyle = `linear-gradient(0deg, ${colors.join(
      ', '
    )})`;

    const legendContent = (
        <>
          <div style={{fontSize: 'x-small'}}>
            {currentData.max().toFixed(1)}
          </div>
          <div style={{background: backgroundStyle, height: '50%', width: '25px'}}>
          </div>
          <div style={{fontSize: 'x-small'}}>
            {currentData.min().toFixed(1)}
          </div>
        </>
    )
    return legendContent;
  };

  const renderHeatmap = () => {
    let fullContent;

    if (!extractedData) {
      fullContent = (
        <div
          style={{
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)'
            }}
          >
            <Spinner
              intent={Intent.PRIMARY}
              size={SpinnerSize.STANDARD}
            />
          </div>
        </div>
      );
      return fullContent;
    };

    // TODO: add segment display (if selected)
    const xLabels = extractedData.positions.map(
      (pos, i) => extractedData.wildtype[i] + ' ' + pos
    );

    const dataMatrix = showEpistaticModel
      ? extractedData.epistaticMatrix
      : extractedData.independentMatrix;

    // TODO: probably nicer to have all selection logic below in reducer
    const heatmapContent = (
      <Heatmap
        data={dataMatrix}
        xLabels={xLabels}
        yLabels={yLabels}
        cellWidth={cellSize + 'px'}
        cellHeight={cellSize + 'px'}
        colorMap={curColorMapper}
        labelMap={({ i, j }) => {
          const subs =
            substitutionOrder[
              reverseSubstitutionOrder ? AA_LIST_PROPERTY.length - j - 1 : j
            ];
          return (
            <span>
              mutant: <b>{xLabels[i] + ' ' + subs}</b>
              <br />
              effect: <b>{dataMatrix[i][j]}</b>
            </span>
          );
        }}
      />
    );

    const controlContent = (
      <ButtonGroup style={{float: 'right'}}>
        {
          // <Button
          //   icon='zoom-in'
          //   title='Zoom in'
          //   onClick={() => {
          //     setCellSize(cellSize + 1);
          //   }}
          //   disabled={cellSize >= MAX_MATRIX_CELL_SIZE}
          // />
          // <Button
          //   icon='zoom-out'
          //   title='Zoom out'
          //   onClick={() => {
          //     setCellSize(cellSize - 1);
          //   }}
          //   disabled={cellSize <= MIN_MATIRX_CELL_SIZE}
          // />
          // <Divider />
        }
        <Button
          icon='exchange'
          title='Switch probability model used for mutation effect calculation'
          onClick={() => {
            setShowEpistaticModel(!showEpistaticModel);
          }}
        >
          {showEpistaticModel ? 'Epistatic' : 'Independent'} model
        </Button>
      </ButtonGroup>
    );

    fullContent = (
      <div>
        {heatmapContent}
        {controlContent}
      </div>
    );
    return fullContent;
  }

  return (
    <Row style={{height: '100%', padding: '5px'}}>
      <Col xs={11} sm={11} md={11} lg={11} xl={11}>
        {renderHeatmap()}
      </Col>
      <Col style={{float: 'right', padding: '5px'}} xs={1} sm={1} md={1} lg={1} xl={1}>
        {renderLegend()}
      </Col>
    </Row>
  );
};

export default MutationPanel;
