import React, { useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { Stage, Component } from 'react-ngl';

import {
  proteinKnownStructureUrl,
  proteinPredictedStructureUrl
} from '../../routes';
import {
  reprListForProtein,
  stageParamsForProtein,
  viewerControlsForProtein
} from './nglSettingsMappings';

export default function StructureViewer(props) {
  const proteinName = props.proteinName;
  const structureFileType = 'pdb';

  const [availableStructures, setAvailableStructures] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState('');
  const [path, setPath] = useState(null);
  const [reprList, setReprList] = useState([]);
  const [reprLists, setReprLists] = useState({});
  const [structureBlobs, setStructureBlobs] = useState({});
  const viewerControls = viewerControlsForProtein(proteinName);
  const stageParams = stageParamsForProtein(proteinName);

  useEffect(() => {
    const fetchProteinStructure = async () => {
      let structures = [];
      let selected = '';
      let blobs = {};
      let reprs = {};


      // Fetch known
      await fetch(proteinKnownStructureUrl(proteinName))
      .then((response) => {
        if (!response.ok) {
          return null;
        }
        return response.blob();
      })
      .then((fetchedStructureBlob) => {
        if (!fetchedStructureBlob) {
          return false;
        }
        structures.push('Known');
        blobs['known'] = fetchedStructureBlob;
        selected = 'known';
        reprs['known'] = reprListForProtein(proteinName);
        return true;
      });
      // Fetch predicted
      await fetch(proteinPredictedStructureUrl(proteinName))
      .then((response) => {
        if (!response.ok) {
          return null;
        }
        return response.blob();
      })
      .then((fetchedStructureBlob) => {
        if (!fetchedStructureBlob) {
          return false;
        }
        structures.push('Predicted');
        blobs['predicted'] = fetchedStructureBlob;
        reprs['predicted'] = reprListForProtein('predicted');
        if (!selected) {
          selected = 'predicted';
        }
        return true;
      });
      // setAvailableStructures(structures);
      setStructureBlobs(blobs);
      setReprLists(reprs);
      setSelectedStructure(selected);
      // setReprList(reprs[selected]);
      // setPath(blobs[selected]);
    };
    fetchProteinStructure();
  }, [proteinName])

  // const handleStructureInputChange = (event) => {
  //   setPath(null);
  //   setReprList([]);
  //   setSelectedStructure(event.target.value);
  // }

  useEffect(() => {
    if (!path && reprList.length === 0 && selectedStructure) {
      setReprList(reprLists[selectedStructure]);
      setPath(structureBlobs[selectedStructure]);
    }
  }, [path, reprList.length, reprLists, structureBlobs, selectedStructure])

  // useEffect(() => {
  //   console.log('+++++++++++++++++++++++');
  //   console.log(reprList);
  //   console.log(path);
  //   console.log('-----------------------');
  // }, [path, reprList])

  return (
    <>
      <h5>
      {
        selectedStructure &&
        selectedStructure.charAt(0).toUpperCase() + selectedStructure.slice(1) + ' Structure'
      }
      </h5>
      <AutoSizer disableHeight>
        {({ width }) => (
          <Stage
            id={'stage'}
            height={'300px'}
            width={width}
            params={{
              backgroundColor: '#f5f5f5',
              quality: 'low',
              ...stageParams,
            }}
            cameraState={{}}
          >
            <Component
              id={'component'}
              path={path}
              reprList={reprList}
              transform={viewerControls}
              loadFileParams={{
                ext: structureFileType,
                name: proteinName,
              }}
            />
          </Stage>
        )}
      </AutoSizer>
      {
      //   <>
      // <label>Select which structure to view:</label>
      // <br/>
      // <select name='structure' onChange={handleStructureInputChange}>
      //   {
      //     availableStructures.map( (structureName) =>
      //       <option
      //         key={structureName.toLowerCase()}
      //         value={structureName.toLowerCase()}
      //       >
      //         {structureName}
      //       </option>
      //     )
      //   }
      // </select>
      //   </>
      }
    </>
  )
}
