import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { makeStyles } from '@material-ui/core/styles';

import {
  proteinJobStatsSummaryUrl,
} from '../../routes';

const useStyles = makeStyles({
  jobStatsSummaryPicker: {
    marginBottom: '1em',
  },
});

export default function JobStatsSummaryTable(props) {
  const classes = useStyles();
  const proteinName = props.proteinName;
  const [numSequences, setNumSequences] = useState(40618);
  const [numSequences80, setNumSequences80] = useState(5752);
  const [percentCoverage, setPercentCoverage] = useState('50%');
  const [allJobStatsSummaries, setAllJobStatsSummaries] = useState({});

  useEffect(() => {
    const fetchJobStatsSummaryData = async () => {
      var jobStatsSummaries = {};
      if (!proteinName) {
        return;
      }
      const fetchedJobStatsSummaryData = await fetch(proteinJobStatsSummaryUrl(proteinName))
      .then((response) => {
        if (!response.ok) {
          return null;
        }
        return response.text();
      }).then((jobStatsSummaryCSVStr) => {
        if (!jobStatsSummaryCSVStr) {
          return null;
        }
        // Then parse it into a list of dictionaries, which makeDataFrame needs
        let jobStatsSummaryCSVArr = jobStatsSummaryCSVStr.trim().split('\n');
        let rowIndex = 0;
        const headers = jobStatsSummaryCSVArr[rowIndex].split(',');
        rowIndex++;

        var jobStatsSummaryArr = [];
        for (rowIndex; rowIndex < jobStatsSummaryCSVArr.length; rowIndex++) {
          let row = jobStatsSummaryCSVArr[rowIndex].split(',');
          var rowDict = {};
          for (let colIndex = 0; colIndex < headers.length; colIndex++) {
            rowDict[headers[colIndex]] = row[colIndex];
          }
          jobStatsSummaryArr.push(rowDict);
        }
        return jobStatsSummaryArr;
      });
      if (!fetchedJobStatsSummaryData) {
        return;
      }
      for (const jobStatsSummary of fetchedJobStatsSummaryData) {
        jobStatsSummaries[jobStatsSummary['prefix']] = jobStatsSummary;
      }
      setAllJobStatsSummaries(jobStatsSummaries);
      setNumSequences(fetchedJobStatsSummaryData[0]['num_seqs']);
      setPercentCoverage(fetchedJobStatsSummaryData[0]['perc_cov']);
      setNumSequences80(fetchedJobStatsSummaryData[0]['N_eff']);
    };
    fetchJobStatsSummaryData();
  }, [proteinName]);

  const handleJobStatsSummaryInputChange = (event) => {
    setNumSequences(allJobStatsSummaries[event.target.value]['num_seqs']);
    setPercentCoverage(allJobStatsSummaries[event.target.value]['perc_cov']);
    setNumSequences80(allJobStatsSummaries[event.target.value]['N_eff']);
  }

  return (
    <>
      <label>Select which job statistics summary to view:</label>
      <br/>
      <Form.Select
        name='job_stats_summary'
        className={`${classes.jobStatsSummaryPicker}`}
        onChange={handleJobStatsSummaryInputChange}>
        {
          Object.keys(allJobStatsSummaries).map( (jobStatSummaryPrefix) =>
            <option
              key={jobStatSummaryPrefix}
              value={jobStatSummaryPrefix}
            >
              {jobStatSummaryPrefix}
            </option>
          )
        }
      </Form.Select>
      <Table striped bordered>
        <tbody>
          <tr>
            <td>Number of Sequences</td>
            <td>Number of Seqs @ 80% Identity</td>
            <td>Percent Coverage</td>
          </tr>
          <tr>
            <td>{numSequences}</td>
            <td>{numSequences80}</td>
            <td>{percentCoverage}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
