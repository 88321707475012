import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { listUrl } from '../../routes';

const ProteinsContext = React.createContext({
  proteinNames: [], fetchProteinNames: () => {}
})

const useStyles = makeStyles({
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function ProteinSearchBar(props) {
  const [proteinList, setProteinList] = useState([]);
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const handleOnClick = () => {
    let matchedValue = value;
    if (proteinList.indexOf(matchedValue.toUpperCase()) >= 0) {
      history.push(`proteins/${matchedValue}`);
    } else {
      // If there was no match, do nothing
      alert('Please select a valid protein.');
    }
  }

  const fetchProteinList = async () => {
    const fetchedProteinList = await fetch(`${listUrl}`).then((response) => response.json());
    let proteinDisplayList = [];
    for (const proteinName of fetchedProteinList) {
      proteinDisplayList.push(proteinName.toUpperCase())
    };
    setProteinList(proteinDisplayList);
  }

  useEffect(() => {
    fetchProteinList()
  }, [])

  return (
    <ProteinsContext.Provider value={{proteinList, fetchProteinList}}>
      <Container>
        <Row className={ classes.centered }>
          <Col xs={8} sm={6} md={4}>
            <Autocomplete
              freeSolo
              value={value}
              onChange={ (event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={ (event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="protein-search-bar"
              options={ proteinList }
              renderInput={
                (params) => {
                  return <TextField {...params}
                    InputLabelProps={{
                      style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '80%',
                      }
                    }}
                    label="Search for protein by name." variant="outlined"
                  />
                }
              }
            />
          </Col>
          <Col xs={1} sm={1} md={1}>
            <Button
              variant="primary"
              onClick={ handleOnClick }
            >
              Go
            </Button>
          </Col>
        </Row>
      </Container>
    </ProteinsContext.Provider>
  )
}
