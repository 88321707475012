import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { makeStyles } from '@material-ui/core/styles';

import sarsSVG from './sarsSVG';
import ProteinSearchBar from '../../components/ProteinSearchBar';

const useStyles = makeStyles({
  container: {
    maxWidth: '70%',
    paddingTop: '75px',
  },
  centeredRow: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBarRow: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2em'
  },
});

export default function HomePage() {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Row className={classes.centeredRow}>
        <div dangerouslySetInnerHTML={sarsSVG()}/>
      </Row>
      <Row className={classes.centeredRow}>
        <Col>
          <p>
            Click on a protein above to view its available data.
            <br/>
            Alternatively, search below for your protein of interest!
          </p>
        </Col>
      </Row>
      <Row className={classes.searchBarRow}>
        <ProteinSearchBar />
      </Row>
    </Container>
  )
}
