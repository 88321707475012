import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    maxWidth: '90%',
  },
});

export default function NavigationBar() {
  const classes = useStyles();

  return (
    <Navbar collapseOnSelect expand='sm' bg='dark' variant='dark'>
      <Container className={classes.container}>
        <Navbar.Brand>SARS-CoV-2</Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link as={Link} exact='true' to='/'>Home</Nav.Link>
            <Nav.Link href='https://evcouplings2.hms.harvard.edu/'>EVcouplings</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href='https://www.sanderlab.org/'>Sander Lab</Nav.Link>
            <Nav.Link href='https://www.deboramarkslab.com/'>Marks Lab</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
