import { Route, Switch } from 'react-router-dom';

import HomePage from '../../pages/HomePage';
import NotFoundPage from '../../pages/NotFoundPage';
import SingleProteinPage from '../../pages/SingleProteinPage';

export default function Main() {
  return (
    <Switch>
      <Route exact path='/' component={HomePage}></Route>
      <Route exact path='/proteins/:proteinName' component={SingleProteinPage}></Route>
      <Route component={NotFoundPage} />
    </Switch>
  )
}
